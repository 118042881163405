.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 100%;
			max-width: 220px;
			background: #fff;
			height: auto;
			margin-top: 0;
			padding: 15px;
			border-radius: 7px;
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {
				margin-right: 10px;
				margin-left: 0;

				a {
					color: var(--footer-font-color);

				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}

				.fa-icon {
					img {
						width: 30px;
						border-radius: 100%;
					}
				}

				/*&:nth-child(2) {
					margin-left: 10px;
				}*/
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa {
				color: var(--color-white);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}

		.col-lg-3 {

			&:nth-child(1) {
				padding-left: 20px;

				@media (max-width:991px) {
					padding-left: 15px;
				}
			}

			&:nth-child(3),
			&:nth-child(4) {
				padding-left: 30px;

				@media (max-width:991px) {
					padding-left: 15px;
				}
			}
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.copy-logo {
			background-color: var(--color-white);
			display: inline-block;
			border-radius: 5px;
			padding: 3px 5px;
		}

		.v-office-logo {
			float: left;
			padding-right: 20px;

			img {
				width: 50px;

			}
		}
	}

}