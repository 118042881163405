.search-page-layout-v3 {
	background-color: var(--color-white);
	margin-top: var(--page-margin-top);

	.feature-box {
		padding: 30px 10px;
		background-color: #f6f2f0;
		border-radius: 10px;
		height: 100%;

		p {
			margin-bottom: 0;
		}

		h4 {
			margin-bottom: 12px;
		}

		.number {
			width: 45px;
			height: 45px;
			border-radius: 50%;
			background-color: var(--color-primary);
			color: #fff;
			display: inline-block;
			padding: 8px 10px 10px 10px;
			margin-bottom: 23px;

			img {
				width: 20px;
				filter: invert(5);
			}
		}
	}

	.ft-serv-bl {
		.ft-serv-content {
			min-height: 150px;

			@media(max-width:992px) {
				min-height: 165px
			}

			h4 {
				color: #000;
			}
		}

		.ft-serv-img {
			height: 250px;
			margin-top: 10px;
			margin-bottom: 30px;

			@media(max-width:992px) {
				height: auto;
			}

			.img-responsive {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.results {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;

		@media (min-width:1200px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		}

		/*	@media (max-width:992px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		} */

		@media (max-width:992px) {
			grid-template-columns: 1fr;
		}

	}

	.more-btn-panel {
		margin-top: 30px;
	}

	.container,
	.container-xl {
		@media (min-width:1200px) {
			/*max-width: 1370px; */
		}

		@media (min-width:1500px) {
			max-width: 1370px;
		}
	}

}

.my-search-token-v2 {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 4px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-right: 8px;

	.close {
		transition: all 0.5s ease;
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		font-size: 14px;
		text-align: center;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 50%;
		font-weight: 400;
		line-height: 1px;
		padding-bottom: 2px;
		opacity: 0;
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}