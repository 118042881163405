.static-view {
	margin-top: var(--page-margin-top);

	&.regionen-v1 {
		p {
			text-align: left !important;
		}
	}

	&.fuer-vermieter-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/inner-vermieter.jpg);
		}

		/*.heading1 {
			font-family: var(--font-family-secondary);
			text-transform: var(--title-text-transform-main);
			font-size: 50px;

			@media (max-width:992px) {
				font-size: 35px;
			}
		}*/

		#accordion {
			&.style1 {
				.card-block {
					background: rgba(246, 242, 240, 1);
					border-radius: 10px;
					margin-bottom: 25px;

					.card-body {
						padding: 0 30px 28px 28px;
					}
				}

				.card-header {
					border: none;
					background-color: transparent;

					.btn {
						font-size: 20px;
						color: var(--h2-font-color);

						&:hover {
							text-decoration: none;
						}
					}
				}
			}
		}

		#accordion1 {
			.card-block {


				margin-bottom: 10px;

				.card-body {
					padding: 10px 15px;
				}
			}

			.card-header {
				border: none;
				background: rgba(243, 240, 238, 1);
				border-radius: 5px;

				.btn {
					font-size: 16px;
					color: rgba(0, 0, 0, 1);
					padding: 10px;
					padding-right: 34px;

					&:after {
						top: 10px;
						right: 15px;
					}

					&:hover {
						text-decoration: none;
					}
				}
			}
		}

		.rundum-service {
			.check {
				li {
					position: relative;
					margin-bottom: 40px;

					&:before {
						content: "";
						background-image: url(RESOURCE/img/icon-check.svg);
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						width: 20px;
						height: 25px;
						position: absolute;
						top: 0;
					}

					p {
						padding-left: 30px;
					}
				}
			}
		}

		.card-header {
			padding: 0;

			button {
				width: 100%;
				text-align: left;
				position: relative;
				padding: 28px;
				padding-right: 50px;

				&[aria-expanded="true"] {
					&:after {
						transform: rotate(-90deg);
					}
				}

				&[aria-expanded="false"] {
					&:after {
						transform: rotate(90deg);
					}
				}

				&:after {
					content: "\f054";
					font-family: Font Awesome\ 5 Free;
					font-weight: 600;
					color: #000;
					font-size: 18px;
					position: absolute;
					top: 32px;
					right: 30px;

				}


			}
		}
	}

	.holiday-apartment {
		h3 {
			span {
				width: 45px;
				height: 45px;
				border-radius: 50%;
				background-color: var(--color-primary);
				color: #fff;
				display: inline-block;
				padding: 10px 10px 10px 16px;
				margin-right: 10px;
			}
		}
	}
}

.faq-v2 {
	.inner-banner {
		background-image: url(RESOURCE/img/inner-banner-faq.jpg);
		background-position: center bottom;
	}
}



.facility-bl {
	position: relative;
	display: block;
	margin-bottom: 30px;

	.facility-title {
		padding: 10px;
		color: #fff;
		text-align: center;
		background-color: var(--overlay-color);
		position: absolute;
		bottom: 0;
		width: 100%;
	}

	.noimage {
		background-image: url(https://via.placeholder.com/450x300&text=Noch%20kein%20Bild%20verfügbar);
		width: 100%;
		height: 320px;
		background-size: cover;
		background-position: center;
		display: block;

		&.region-9162 {
			background-image: url(RESOURCE/img/region-1.jpg);
		}

		&.region-9160 {
			background-image: url(RESOURCE/img/region-2.jpg);
		}

		&.region-9161 {
			background-image: url(RESOURCE/img/region-3.jpg);
		}

		&.region-9163 {
			background-image: url(RESOURCE/img/region-4.jpg);
		}

		&.region-9157 {
			background-image: url(RESOURCE/img/region-5.jpg);
		}

	}
}

.img-full {
	height: 350px;

	img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
}